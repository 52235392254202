(function ($) {
  $(function () {



    'use strict';


    // Контрольные точки
    var bpPhone = 500,
    bpTablet = 800,
    bpDesktop = 1100,
    bpLgDesktop = 1400;



    // SVG
    svg4everybody();



    // Показ сокрытие информации в шапке на мобильнм разршении
    var $headerInfoTrigger = $('.js-header-info-trigger');

    $headerInfoTrigger.on('click', function () {
      var $target = $($(this).attr('href'));
      $target.slideToggle(300);
      return false;
    });



    // $(window).resize(function () {
    //   $('#headerInfo').css('display', '');
    // });



    // Скрываемые при загрузке блоки
    var $hiddenElm = $('.js-hidden');
    $hiddenElm.hide();



    // Аккордеон
    var $accordion = $('.js-accordion');

    $accordion.on('click', function () {
      var $target = $(this).closest('.accordion__item').find('.accordion__content');

      if($(this).hasClass('accordion__trigger--active')) {
        $target.slideUp(300);
        $(this).removeClass('accordion__trigger--active');
      } else {
        $target.slideDown(300);
        $(this).addClass('accordion__trigger--active');
      }

      return false;
    });



    // Навигация
    var $navTrigger = $('.js-nav-trigger');
    $navTrigger.on('click', function () {
      $(this).toggleClass('nav__trigger--type_close');
      $(this).closest('.page__nav').toggleClass('page__nav--state_open').find('.nav').toggleClass('nav--state_open');
      $(this).closest('.nav').find('.nav__list').toggleClass('nav__list--state_open');
      return false;
    });



    // Поднавигация
    var $navItemTrigger = $('.js-nav-item-trigger')
    $navItemTrigger.on('click', function () {
      if($(this).hasClass('nav__item-trigger--state_open')) {
        $(this).removeClass('nav__item-trigger--state_open');
        $(this).closest('.nav__item').removeClass('nav__item--state_open');
        $(this).closest('.nav__wrapper').find('.nav__dropdown').slideUp(300);
      } else {
        $(this).addClass('nav__item-trigger--state_open');
        $(this).closest('.nav__item').addClass('nav__item--state_open');
        $(this).closest('.nav__wrapper').find('.nav__dropdown').slideDown(300);
      }
      return false;
    });

    // $(window).resize(function () {
    //   $('.nav__dropdown').css('display', '');
    // });



    // Поднавигация группы ссылок
    var $navItemTrigger = $('.js-sections-group-trigger')
    $navItemTrigger.on('click', function () {
      if($(this).hasClass('sections-group__trigger--state_open')) {
        $(this).removeClass('sections-group__trigger--state_open');
        $(this).closest('.sections-group__col').find('.sections-group__title').removeClass('sections-group__title--state_open');
        $(this).closest('.sections-group__col').find('.sections-group__list').slideUp(300);
      } else {
        $(this).addClass('sections-group__trigger--state_open');
        $(this).closest('.sections-group__col').find('.sections-group__title').addClass('sections-group__title--state_open');
        $(this).closest('.sections-group__col').find('.sections-group__list').slideDown(300);
      }
      return false;
    });

    // $(window).resize(function () {
    //   $('.sections-group__list').css('display', '');
    // });



    // Всплывающее окно
    var $modal = $('.js-modal');
    $modal.fancybox({
      closeBtn: false,
      smallBtn: false
    });

    var $fancybox = $('.js-fancybox');
    $fancybox.fancybox();

    var $modalClose = $('.js-modal-close');
    $modalClose.on('click', function () {
      $.fancybox.close();
      return false;
    });



    // Плавная прокрутка к якорю
    var $smootcScrolling = $('.js-smootc-scrolling');
    $smootcScrolling.on('click', function () {
      $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);

      window.location.hash = this.hash;
      return false;
    });



    // Сетка с переменной высотой ячейки (разводящая новостей)
    $('.js-masonry').masonry({
      itemSelector: '.entry-masonry__item',
      columnWidth: '.entry-masonry__item'
    });



    // Карусель
    var $carousel = $('.js-carousel');
    $carousel.slick({
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 100,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1
    });



    // Главная карусель
    var $mainCarousel = $('.js-main-carousel');
    $mainCarousel.slick({
      autoplay: true,
      autoplaySpeed: 50000,
      speed: 700,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<button class="main-carousel__arrow main-carousel__arrow--type_prev">Предыдущий<svg class="main-carousel__arrow-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/i/icon.svg#icon_prev"></use></svg></button>',
      nextArrow:'<button class="main-carousel__arrow main-carousel__arrow--type_next">Следующий<svg class="main-carousel__arrow-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/i/icon.svg#icon_next"></use></svg></button>'
    });



    // Карусель фотографий
    var $photoCaroselSizeFour = $('.js-carousel-size-4');

    $photoCaroselSizeFour.slick({
      autoplay: true,
      autoplaySpeed: 50000,
      speed: 700,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: '<button class="photo-carousel__arrow photo-carousel__arrow--type_prev">Предыдущий<svg class="photo-carousel__arrow-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/i/icon.svg#icon_prev"></use></svg></button>',
      nextArrow:'<button class="photo-carousel__arrow photo-carousel__arrow--type_next">Следующий<svg class="photo-carousel__arrow-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/i/icon.svg#icon_next"></use></svg></button>',
      responsive: [
        {
          breakpoint: bpDesktop,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: bpTablet,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: bpPhone,
          settings: {
            slidesToShow: 1,
            arrows: false,
            variableWidth: true
          }
        }
      ]
    });

    var $photoCaroselSizeFive = $('.js-carousel-size-5');

    $photoCaroselSizeFive.slick({
      autoplay: true,
      autoplaySpeed: 50000,
      speed: 700,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      prevArrow: '<button class="photo-carousel__arrow photo-carousel__arrow--type_prev">Предыдущий<svg class="photo-carousel__arrow-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/i/icon.svg#icon_prev"></use></svg></button>',
      nextArrow:'<button class="photo-carousel__arrow photo-carousel__arrow--type_next">Следующий<svg class="photo-carousel__arrow-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/i/icon.svg#icon_next"></use></svg></button>',
      responsive: [
        {
          breakpoint: bpDesktop,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: bpTablet,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: bpPhone,
          settings: {
            slidesToShow: 1,
            arrows: false,
            variableWidth: true
          }
        }
      ]
    });


    // Разворачивание списка услуг
    $('.js-collapsed-sections-list .js-show-more').click(function(e) {
      e.preventDefault();
      $(this).closest('.js-collapsed-sections-list').toggleClass('is-expanded');
    });


    // Карта
    var mapMobileScroll = function (YaMap, method) {
      if (typeof YaMap == 'undefined' || typeof method == 'undefined') return false;

      if (method) {
        YaMap.behaviors.enable('scrollZoom');
        YaMap.behaviors.enable('drag');
      } else {
        YaMap.behaviors.disable('scrollZoom');
        YaMap.behaviors.disable('drag');
      }
    };

    var YaMap, placemark, $map = $('#map, #footer-map');
    $map.each(function() {
      var $map = $(this);

      var mapX = $map.data('mapX') || 54,
      mapY =  $map.data('mapY') || 37,
      zoom =  $map.data('mapZoom') || 14,
      hintContent =  $map.data('mapHintContent') || '',
      balloonContent =  $map.data('map-balloon-content' || ''),
      icon = $map.data('mapIcon') || '/i/map-marker.png';

      ymaps.ready(function () {
        YaMap = new ymaps.Map($map.attr('id'), {
              center: [mapX, mapY],
              zoom: zoom
          }, {
              searchControlProvider: 'yandex#search'
          });
          placemark = new ymaps.Placemark(YaMap.getCenter(), {
              id: 'address',
              hintContent: hintContent,
              balloonContent: balloonContent
          }, {
              // Тип макета.
              iconLayout: 'default#image',
              // Изображение иконки метки.
              iconImageHref: icon,
              // Размеры метки.
              iconImageSize: [78, 92],
              // Смещение левого верхнего угла иконки относительно
              // её "ножки" (точки привязки).
              iconImageOffset: [-39, -92],
              visible: true
          });

        YaMap.geoObjects.add(placemark);

        if($(window).width() <= bpTablet) {
          mapMobileScroll(YaMap, false);
        }

        $(window).resize(function () {
          if($(window).width() <= bpTablet) {
            mapMobileScroll(YaMap, false);
          } else {
            mapMobileScroll(YaMap, true);
          }
        });

      });
    });

  $('.js-toggle-footer-map').click(function(e) {
    e.preventDefault();
    $('body').toggleClass('map-is-expanded');
  });
  $('.js-toggle-mobile-footer-map').click(function(e) {
    e.preventDefault();
    $('#footer-map').toggle();
  });

  $('#Callback_phone, #CallDoctor_phone, #MakeAppointment_phone').inputmask("+7(999) 999-99-99");


  $('.section__desc').each(function() {
    let lineHeight = parseFloat($(this).find('.section__text').css('line-height'));
    let maxHeight = lineHeight * 8;
    var SectionTextHeight = $(this).find('.section__text').height();
    if (SectionTextHeight > maxHeight) {
      $(this).find('.section__text').css({"height": maxHeight});
      $(this).find('.view-all').show();
    }
  });

  $('.view-all').on("click", function() {
    let lineHeight = parseFloat($(this).closest('.section__desc').find('.section__text').css('line-height'));
    let maxHeight = lineHeight * 8;
    if ($(this).hasClass("active")) {
      $(this).closest('.section__desc').find('.section__text').css({"height": maxHeight});
      $(this).text("Смотреть полностью").removeClass("active");
    } else {
      $(this).closest('.section__desc').find('.section__text').css({"height": "auto"});
      $(this).text("Скрыть").addClass("active");
    }
  });


  });
})(jQuery);
